// Mixins
@function em($target, $context: $base-font-size) {
   @if $target == 0 { @return 0 }
   @return $target / $context + 0em;
}


// PX TO REM
// Usage
//@include rem(font-size, 14px);
//@include rem(margin, 0 auto 1);
//@include rem(padding-bottom, 3%);

@mixin rem($property, $values) {
  // Create a couple of empty lists as output buffers.
  $font-size: $base-font-size;
  $px-values: ();
  $rem-values: ();

  // Loop through the $values list
  @each $value in $values {
    // For each property value, if it's in rem or px, derive both rem and
    // px values for it and add those to the end of the appropriate buffer.
    // Ensure all pixel values are rounded to the nearest pixel.
    @if $value == 0 or $value == 0px {
      // 0 -- use it without a unit
      $px-values: join($px-values, 0);
      $rem-values: join($rem-values, 0);
    } @else if type-of($value) == number and not unitless($value) and (unit($value) == px) {
      // px value given - calculate rem value from font-size
      $new-rem-value: $value / $font-size;
      $px-values: join($px-values, round($value));
      $rem-values: join($rem-values, #{$new-rem-value}rem);
    } @else if type-of($value) == number and not unitless($value) and (unit($value) == "%") {
      // % value given - don't add px or rem
      $px-values: join($px-values, #{$value});
      $rem-values: join($rem-values, #{$value});
    } @else if $value == auto {
      // auto - don't add px or rem
      $px-values: join($px-values, auto);
      $rem-values: join($rem-values, auto);
    } @else {
      // unitless value - use those directly as rem and calculate the px-fallback
      $px-values: join($px-values, round($value * $font-size));
      $rem-values: join($rem-values, #{$value}rem);
    }
  }

  // output the converted rules
  #{$property}: $px-values;
  #{$property}: $rem-values;
}

/**
 * Responsive mixin. The media breakpoints are as defined
 * in the twitter bootstrap framework:
 *
 * - phone
 * - tablet-portrait
 * - tablet-landscape-desktop
 * - large-desktop
 *
 * Additional parameters for tagetting retina and non-retina
 * devices
 *
 * - retina
 * - non-retina
 *
 * Moreover, a specific value in px can be passed which is
 * used to generate a max-width media query.
 * Example
 * @include respondto(medium-desktop) {
 *  @include rem( margin-left,173px);
 *    float: left;
 *  }
 *}
 */
@mixin respond-to($media) {
    /* Landscape phones and down */
    @if $media == phone {
        @media only screen and (max-width: 480px) { @content; }
    }
    /* Landscape phone to portrait tablet */
    @else if $media == tablet-portrait {
        @media only screen and (max-width: 767px) {@content; }
    }
    /* Portrait tablet to landscape and desktop */
    @else if $media == tablet-landscape-desktop {
        @media only screen and (min-width: 768px) and (max-width: 979px) { @content; }
    }
    /* Tablet */
    @else if $media == medium-tablet {
        @media only screen and (max-width: 1024px) { @content; }
    }
    /* Medium desktop */
    @else if $media == medium-desktop {
        @media only screen and (max-width: 992px) { @content; }
    }
    /* Large desktop */
    @else if $media == large-desktop {
        @media only screen and (min-width: 1200px) { @content; }
    }
    // Non-Retina
    @else if $media == non-retina {
        @media only screen and (-webkit-max-device-pixel-ratio: 1) { @content; }
    }
    // Retina Only
    @else if $media == retina {
        @media screen and (-webkit-min-device-pixel-ratio: 2) { @content; }
    }
    // Specific max width
    @else {
        @media only screen and (max-width: #{$media}px) { @content; }
    }
}

@mixin respondto($media...) {
    @each $mediatype in $media {
        @include respond-to($mediatype) {@content}
    }
}
