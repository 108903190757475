// Fonts replacement for Gothic Roman.
%News_Gothic_regular {
  font-family: news-gothic-std,sans-serif;
  font-weight: 400;
}
%News_Gothic_bold {
   font-family: news-gothic-std,sans-serif;
   font-weight: 700;
}

html {
  font-size: 100%; /* Sets up the Base 10 stuff */
}

body {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  webkit-tap-highlight-color: #222222;
  color:  $gray_5;
  @extend %News_Gothic_regular;
  font-size: 16px;
}
@media screen and (min-width: 500px){
  body {
    font-size: 14px;
  }
}
@media screen and (min-width: 570px){
  body {
    font-size: 15px;
  }
}
@media screen and (min-width: 620px){
 body {
    font-size: 16px;
  }
}
@media screen and (min-width: 680px){
  body {
    font-size: 17px;
  }
}

strong {
   @extend %News_Gothic_bold;
   font-weight: normal;
   font-variant: normal;
   font-style: normal;
}

span.icon.glyphicon {
  display: none;
}

a {
  text-decoration: none;
  color:  $blue;
  transition: all .25s ease-in-out;
  &:hover,
  &:focus {
    color:  $gray_5;
    text-decoration: none;
  }
}

h1,
.container-content h1.page-header {
  color: $blue;
  @include rem(font-size,26px);
  @include rem(margin-bottom,25px);
  @include rem(line-height,25px);
  @media (max-width: $screen-xs) {
    @include rem(margin-bottom,15px);
  }
}

h2 {
  @include rem(font-size,24px);
}

h3 {
  @include rem(font-size,22px);
}

.field-name-body {
  color:  $gray_5;
}

ul {
  @extend %reset-list;
  list-style-image: url(../images/bullet-gray.png);
  @include rem(margin-left, 15px);
}
ol {
  @include rem(margin-left, 0);
  padding-left: 0;
}
li {
  @include rem(margin-bottom, 2px);
  @include rem(margin-left, 0);
}
