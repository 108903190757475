/* DEBUG */
.view-level-2-menus .view-footer-sections .item-list ul li:nth-child(5n+1),
.region-content-bottom .view-gerelateerde-inhoud .item-list ul li:nth-child(5n+1) {
  padding-left: 10px;
}
@media (max-width: 768px) {
.view-level-2-menus .view-footer-sections .item-list ul li:nth-child(3n+1),
  .region-content-bottom .view-gerelateerde-inhoud .item-list ul li:nth-child(3n+1) {
    padding-left: 10px;
  }
}
@media (max-width: 768px) {
  .view-level-2-menus .view-footer-sections .item-list ul li,
  .region-content-bottom .view-gerelateerde-inhoud .item-list ul li {
    flex-basis: calc(50% - 10px);
    padding-right: 0;
  }
   aside .view-gerelateerde-inhoud .item-list li {
    flex-basis: calc(50%);
    padding: 0 10px;
  }
}

@media (max-width: 480px) {
  .view-level-2-menus .view-footer-sections .item-list ul li,
  .region-content-bottom .view-gerelateerde-inhoud .item-list ul li {
    flex-basis: calc(100% - 10px);
    padding-right: 0;
  }
}

.dropdown-menu {
  padding-top: 0;
   padding-bottom: 0;
}
.primary.mobile .navbar-nav .dropdown-menu li a,
.primary.mobile .navbar-nav.secondary .dropdown-menu li a {
  padding-top: 10px;
  padding-bottom: 10px;
}

// @media (min-width: 768px) {
//   .main-navigation .navbar-nav {
//     margin-top: 4px;
//   }
// }

@media (min-width: 768px) {
  .wysiwyg_wrapper [class*="col"]:first-child {
    padding-left: 20px;
  }
  .wysiwyg_wrapper [class*="col"]:last-child {
      padding-right: 0px;
  }
}
@media (min-width: 768px) {
  .main-navigation .navbar-nav li {
    border: none;
  }
  .main-navigation .navbar-nav {
    padding: 0 10px;
  }
}
@media (max-width: 768px) {
 .levensverzekeringen-menus {
   border: 0;
  }
}

.main-navigation .navbar-nav > .navbar-nav {
  margin-left: -35px;
}
aside {
  padding-right: 0 !important;
}
