  .html #sliding-popup .popup-content #popup-buttons button.hide-popup-button, .html #sliding-popup .popup-content #popup-buttons button.find-more-button, .html #sliding-popup .popup-content #popup-buttons button.agree-button,
#sliding-popup .eu-cookie-compliance-default-button, #sliding-popup .eu-cookie-compliance-hide-button, #sliding-popup .eu-cookie-compliance-more-button-thank-you, #sliding-popup .eu-cookie-withdraw-button, .eu-cookie-withdraw-button, .eu-cookie-compliance-save-preferences-button {
    text-shadow:  none !important;
    border: none;
    background:#d9d9d9 !important;
    color: #585858 !important;
    padding: 5px 20px;
		box-shadow: none !important;
		border: 1px solid #fff;
  	border-radius: 0.65em;
		font-style: normal;
		font-weight: normal;
		&:hover,
		&:focus {
			background:#d9d9d9 !important;
		}
}


.html #sliding-popup {
    display: block;
    margin: 0;
    padding: 10px;
    right: 0;
    text-align: center;
    width: 100%;
    z-index: 99999;
    background: #33436e !important;
}
