// Helper classes
@media (min-width: $screen-sm-min) {
  .no-padding {
    padding: 0;
  }
}

// Navigation
.navbar-toggle {
	display: block;
}
.navbar-default {
  background-color: transparent;
  border-color: transparent;
}
#navbar {
  padding: 0;
}

.main-navigation {
	.navbar-nav {
		a {
			&:hover,
			&:focus {
				background: none;
			}
		}
	}
}
.caret {
  border-left: 4px solid rgba(51,67,110,0);
  border-right: 4px solid rgba(51,67,110,0);
  border-top: 4px dashed;
  display: inline-block;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  width: 0;
  float: right;
  margin-top: 9px;
}

.container {
  padding: 0 20px;
}

// Bootstrap grids
header {
	.container {
    padding-left: 20px;
    padding-right: 20px;
    @media (min-width: $screen-sm-min) {
      padding-bottom: 0;
    }
    & > .row > [class*=col]:first-child,
    & > .row > [class*=col]:nth-of-type(2) {
      padding-top: 0.4em;
      padding-bottom: 8px;
      @media (max-width: $screen-sm) {
        padding-top: 0.6em;
        padding-bottom: 0;
      }
    }
    & > .row > [class*=col]:nth-of-type(2) {
      padding-bottom: 5px;
      //border-left: 1px solid $gray_2;
      padding-right: 0;
      padding-top: 5px;
      @media (max-width: $screen-sm) {
        padding-top: 0.6em;
        padding-bottom: 0;
      }
    }
    & > .row > [class*=col]:last-child {
      padding-bottom: 5px;
      padding-top: 0;
    }
  }
}

// Breadcrumbs
.breadcrumb {
  background-color: transparent;
  border-radius: 0;
}
.breadcrumb > li + li::before {
  color: inherit;
  content: "> ";
  padding: 0;
}

// Jumbotron
.jumbotron h1 ,
.jumbotron .h1 {
  @include rem(font-size, 20px);
}

.jumbotron {
  background-color: transparent;
  margin-bottom: 0;
}

// Page header
.page-header {
  border-bottom: none;
  margin:0;
  padding: 0;
}

.container .jumbotron, .container-top .jumbotron {
  padding: 0;
}
