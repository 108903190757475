// Spotlight
#news-spotlight {
	background-color: $blue_2;
	.container {
		padding-left: 0;
	}
	#block-views-news-spotlight-block {
		// background:  $gray_5;
		// border-radius: 10px 10px 0 0;
		padding: 10px 15px;
		.news-label {
			text-transform: uppercase;
			color: $white;
			@extend %News_Gothic_bold;
			margin-left: -10px;
		}
		.news-link {
			color: $white;
			margin-left: 1em;
		}
	}
	#block-views-newsflash-block,
	#block-views-newsflash-block-1 {
		padding: 10px 15px;
		text-align: center;
		.field-name-field-teaser {
			color: $white;
			@extend %News_Gothic_bold;
			@include rem(font-size, 17px);
		}
		a {
			@include rem(font-size, 15px);
		}
	}
}

// Homepage main sections
.view-homepage-main-sections {
	@media (min-width: $screen-xs-min) {
		border-bottom: 1px solid $gray_2;
	}
	.field-pagina-intro {
		padding-top: 20px;
	}
	.view-content {
		display: flex;
		flex-wrap: wrap;
	}
	.rows-wrapper {
		display: flex;
		flex-direction: column;
		flex: 1 1 auto;
	}
	.views-row {
		display: flex;
		flex-direction: column;
		&:first-child {
			@media (max-width: $screen-xs-max) {
				margin-bottom: 2rem;
			}
		}
	}
	.views-field-field-pagina-intro {
		flex: 1 1 auto;
		@media (max-width: $screen-xs) {
			display: none;
		}
	}
	.views-field.views-field-views-conditional {
		/*  $screen-xs-max */
		@media (max-width: $screen-xs) {
			display: none;
		}
	}
	.sections-header .bttn {
		@media (max-width: $screen-xs) {
			border-radius: 15px;
		}
	}
}
.sections-header {
	z-index: 10;
	position: relative;
	&:before {
		content:"";
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: $white;
		z-index: -2;
		border-radius: 0;
		@media (max-width: $screen-xs) {
			display: none;
		}
	}
	&:after {
		content:"";
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: $blue;
		border-radius: 15px 15px 0 0;
		z-index: -1;
		@media (max-width: $screen-xs) {
			display: none;
		}
	}
	h1 {
		margin: 0;
	}
	a {
		color: $white;
		text-decoration: none;
		@include rem(font-size, 18px);
		padding: 15px 25px 15px 25px;
		display: block;
	}
}

// .field-name-body {
// 	@include rem(font-size, 15px);
// }
.views-field-field-pagina-intro {
	background: $gray_6;
	color: $gray_4;
	@include rem(font-size, 17px);
	padding: 0px 25px 15px 25px;
	ul {
		@extend %reset-list;
		list-style-image: url(../images/bullet-blue.png);
		@include rem(margin-left, 15px);
	}
	li {
		@include rem(margin-bottom, 2px);
		// &:before{
		// 	content:'\00B7';
		// 	padding-right: 10px;
		// 	@extend %News_Gothic_bold;
		// 	@include rem(font-size, 20px);
		// }
	}
}
.read-more-link {
	background: $gray_6;
	overflow: hidden;
	a {
		background: $blue_2;
		color: $white;
		border-radius: 10px 10px 0 0;
		float: right;
		text-decoration: none;
		padding: 5px 20px;
		@include rem(font-size, 15px);
		margin-right: 25px;
	}
}

// Footer
footer {
	padding-bottom: 20px;
	background-color: $gray_1;
	@media (max-width: $screen-xs) {
		@include rem(margin-top,20px);
		.view-parent-and-childeren-menu .view-content.mobile {
			display: none !important;
		}
		.footer-content .container:first-child [class*=col] {
			width: 100% !important;
			margin: 0;
			padding: 0 10px !important;
		}
	}
	@media (min-width: $screen-sm-min) {
		.region-footer-bottom {
			[class*=col]:first-child {
				padding-left: 0;
			}
			[class*=col]:last-child {
				padding-right: 0;
			}
		}
	}

	// @media (min-width: $screen-md) {
	// 	position: absolute;
	// 	right: 0;
	// 	bottom: 0;
	// 	left: 0;
	// 	min-height: 400px;
	// }
	.footer-content {
		// .container {
		// 	border-top: 1px solid $gray_7;
		// }
		.footer_content_top {
			background: $gray_7;
			padding: 10px 0;
			[class*=col] {
				@media (max-width: $screen-xs-max) {
					@include rem(margin-bottom, 25px);
				}
				padding-top: 15px;
				padding-bottom: 15px;
				padding-left: 0;
				padding-right: 0;
				@media (max-width: $screen-xs) {
					background-color:  transparent;
					//border-bottom: 1px solid $gray_7;
				}
				[class*=col] {
					padding-left: 20px;
					padding-top: 0;
					padding-bottom: 0;
					padding-right: 0;
					&:first-child {
						padding-left: 0;
					}
					@media (max-width: $screen-sm-max) {
						&.views-row-4 {
							padding-left: 0;
						}
					}
					@media (max-width: $screen-xs-max) {
						&.views-row-3 {
							padding-left: 0;
						}
						&.views-row-4 {
							padding-left: 20px;
						}
					}
				}
			}
		}
		.footer_content_bottom {
			padding-top: 10px;
		}
		// @media (max-width: $screen-xs) {
		// 	.footer_content_bottom {
		// 		border-top: 1px solid $gray_7;
		// 	}
		// }
	}
	.view-content {
		display: flex;
		flex-wrap: wrap;
		& >  .views-row {
			display: flex;
			flex-direction: column;
			border-right: 1px solid $gray_6;
			padding: 0 1.5em;
			&:last-child {
				border: none;
			}
			@media (max-width: $screen-sm-max) {
				&.views-row-3 {
					border-right: 0;
				}
			}
			@media (max-width: $screen-xs-max) {
				&.views-row-2 {
					border-right: 0;
				}
				&.views-row-3 {
					border-right: 1px solid $gray_6;
				}
			}
		}
	}
	h1 {
		@include rem(font-size, 15px);
		color: $gray_5;
		margin: 0 0 15px 0;
		a {
			color: $gray_5;
			@extend %News_Gothic_bold;
			&:hover,
			&:focus {
				color: $gray_5;
				text-decoration: none;
			}
		}
	}
	a {
		color: $white;
		@include rem(font-size, 15px);
		@extend %News_Gothic_regular;
		&:hover,
		&:focus {
			color: $gray_5;
			text-decoration: none;
		}
	}
	ul {
		@extend %reset-list;
		li {
			@include rem(margin-bottom , 2px);
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

footer .view-contact-link .view-content > .views-row {
	padding-left: 0;
	padding-right: 0;
}
.view-contact-link  {
	position: relative;
	margin-top: -15px;
	.bttn {
		background-color: $blue;
		border-radius: 0 0 12px 12px;
		padding: 5px 15px 10px 15px;
		display: inline-block;
		&:hover,
		&:focus {
			color: $white;
			background-color: $gray_4;
		}
		@media (max-width: $screen-xs) {
			display: block;
			border-radius: 15px;
			@include bttn-large;
		}
	}
}

footer {
	// margin-top: 40px;
	.content {
		@include rem(font-size ,12px);
		color: $gray_3;
	}
	.container + .container {
		padding: 0 20px;
	}
	.region-footer-bottom .bean-custom-block {
		color: $gray_3;
		padding-top: 5px;
		display: inline-block;
		p {
			@include rem(font-size ,12px);
			margin-bottom: 0;
		}
		a,
		& + a {
			color: $gray_5;
			@include rem(font-size ,12px);
			&:hover,
			&:focus {
				color: $gray_5;
			}
		}
		& + a {
			display: inline-block;
		}
	}
	.disclaimer-link,
	.field-name-field-body {
		display: inline-block;
	}
	.brandle-notice {
		padding-top: 5px;
		color: $gray_3;
		@include rem(font-size ,12px);
		text-align: right;
		a {
			color: $gray_3;
			@include rem(font-size ,12px);
			&:hover,
			&:focus {
				color: $gray_3;
			}
		}
	}
}

// Levensverzekeringen
.levensverzekeringen-menus {
	border-bottom: 1px solid $gray_2;
}
.main-container .view-parent-and-childeren-menu {
	margin-top: 40px;
	.view-footer-sections {
		padding: 15px 25px;
		ul {
			@extend %reset-list;
			li {
				@include rem(margin-bottom,3px);
				@include rem(font-size ,17px);
			}
			a {
				color: $gray_5;
				&:hover,
				&:focus {
					color: $blue;
					text-decoration: none;
				}
			}
		}
	}
	.view-content {
		display: flex;
		flex-wrap: wrap;
		> .views-row {
			display: flex;
			flex-direction: column;
			position: relative;
			@media (max-width: $screen-md) {
				@include rem(margin-bottom,40px);
			}
			& > .content-wrapper,
			.views-field-body {
				background-color: transparent;
				display: flex;
				flex-direction: column;
				flex: 1 1 auto;
				.views-field-view {
					flex: 1 1 auto;
					background-color: $gray_6;
				}
			}
			.views-field-body {
				background-color: $gray_6;
				padding: 1em;
				> .field-content {
					> p {
						line-height: 1.4 !important;
					}
				}
			}
			.sections-header:before {
				background-color: transparent;
			}
		}
		.views-field-link-title-1 {
			display: flex;
			justify-content: flex-end;
			padding-top:1em;
			background-color: $gray_6;
		}
		.bttn.blue-light {
			padding: 0.45em 1.4em;
			background: $blue_2;
			color: $white;
			text-align: center;
			border-radius: 15px 0 0 0;
			@include rem(font-size ,15px);
		}
	}
	.views-row .content-wrapper .views-field-view {
		background-color: $gray_6;
		background-repeat:  no-repeat;
		background-position: 80% 95%;
	}
	.views-row-1 .content-wrapper .views-field-view{
		background-image: url(../images/tak_21.png);
	}
	.views-row-2 .content-wrapper .views-field-view{
		background-image: url(../images/tak_23.png);
	}
	.views-row-3 .content-wrapper .views-field-view{
		background-image: url(../images/tak_26.png);
	}
}

[class*=tak] .view-summary .view-content {
	background-position: calc(100% - 20px) calc(0px);
	background-repeat: no-repeat;
	min-height: 100px;
}

.tak-21 .view-summary .view-content {
	background-image: url(../images/tak_21.png);
}
.tak-23 .view-summary .view-content {
	background-image: url(../images/tak_23.png);
}

.tak-26 .view-summary .view-content {
	background-image: url(../images/tak_26.png);
}

//  Sub menus level 2
//  ----------------------------------------------------------------------
.view-level-2-menus {
	padding-top: 20px;
}
.view-level-2-menus .view-footer-sections,
.region-content-bottom .view-gerelateerde-inhoud  {
	margin-top: 20px;

	.views-row {
		position: relative;
	}
	.views-row:before {
		border-bottom: 1px solid $gray_2;
		content: "";
		height: 100%;
		left: 100%;
		position: absolute;
		top: 1px;
		width: 500%;
	}
	.bttn.upper,
	.bttn.upper.thin {
		border-radius: 10px 10px 0 0;
	}
	.item-list {
		ul {
			@extend %reset-list;
			display: flex;
			flex-wrap: wrap;
			overflow: hidden;
			li {
				flex-basis: calc(20% - 10px);
				flex-grow: 0;
				flex-shrink: 0;
				text-align: center;
				margin: 0 0 1rem 0;
				padding-left: 10px;
				padding-right: 10px;
				margin-bottom: 1rem;
				align-self: flex-start;
				justify-content: flex-start;
				position: relative;
				border-bottom: 1px solid $gray_2;
				@media (max-width: $screen-md) {
					flex-basis: calc(50% - 10px);
				}
				@media (max-width: $screen-xs) {
					flex-basis: calc(100% - 10px);
					padding-left: 10px;
					padding-right: 0px;
				}
				&:last-child {
					margin-right: 0;
				}
				&:first-child {
					padding-left: 10px !important;
				}
				&:nth-child(3n+1) {
					@media (max-width: $screen-sm) {
						padding-left: 20px;
					}
					@media (min-width: $screen-xs) {
						padding-left: 10px;
					}
				}
				&:nth-child(5n+1) {
					@media (min-width: $screen-sm-min) {
						padding-left: 10px;
					}
				}
			}
			a {
				padding: 5px;
				@include rem(font-size ,15px);
			}
		}
	}
}

// Sidebar
aside .view-gerelateerde-inhoud {
	.item-list {
		ul {
			@extend %reset-list;
		}
		li {
			margin-bottom: 20px;
			padding: 0 20px;
			border-bottom: 1px solid $gray_2;
		}
	}
}

// Nieuws
.view-nieuws-overzicht {
	padding: 30px 0;
	.item-list {
		ol {
			@extend %reset-list;
		}
		li {
			@include rem(margin-bottom,30px);
			@include rem(padding-bottom,15px);
			border-bottom: 1px solid $gray_2;
		}
	}
	a {
		color: $blue;
		&:hover,
		&:focus {
			color: $gray_5;
		}
	}
	h1 {
		[class*=date] {
			display: block;
			margin-top: 0.5em;
			color: $blue;
			@include rem(font-size,14px);
		}
	}
}

// makelaars + map
.views-exposed-form {
	label {
		font-weight: normal;
		@include rem(font-size ,15px);
	}
  .views-exposed-widget {
    display: inline-block;
    padding: 0 1em 0.5em 0;
    &.views-widget-filter-title,
    &.views-widget-filter-field_producten_tid {
    	label {
    		display: inline-block;
				vertical-align: top;
				margin: .5em 0;
    	}
    }
    &.views-submit-button,
    &.views-reset-button {
      float: none;
      display: inline-block;
      min-width: 150px;
      padding-right: 5px;
    }
    &.views-widget-filter-distance {
    	width: 100%;
    	label {
    		display: block;
    	}
    }
    .views-widget {
    	padding: 0;
    	display: inline-block;
    }
  }
  .form-item.form-group {
    display: block;
    float: left;
    padding-right: 1em;
    &.form-item-distance-postal-code,
    &.form-item-distance-search-distance,
    &.form-item-distance-search-units {
      width: 100px;
      padding-right: 0;
    }
    &.form-item-distance-postal-code {
    	padding-right: 1em;
    }
    &.form-item-distance-search-units {
      padding-top: 26px;
    }
  }
}

// dochtermaatschappijen
.view-dochtermaatschappijen {
	.group-right {
		text-align: center;
	}
	.views-row {
		padding-bottom: 1em;
		border-bottom: 1px solid $gray_2;
		&.views-row-last {
			border-bottom: none;
		}
	}
}

footer {
	.view-footer {
		.view-content {
			display: block;
		}
	}
}

