a.bttn,
.bttn a,
.btn {
  display: block;
  border-radius: 0.65em;
  text-decoration: none;
  //margin-right: 30px;
  color: $white;
  text-align: center;
  text-decoration: none;
  padding: 5px 20px;
  &:hover,
  &:focus {
    color: $white;
    text-decoration: none;
  }
}

.header.bttn {
   padding: 15px 25px 15px 25px;
   border-radius: 15px 15px 0 0;
}
.blue-green {
  background: $blue_2;
  &:hover,
  &:focus {
    background: $blue;
  }
}
.blue-light {
  background: $blue_2;
  &:hover,
  &:focus {
    background: $blue !important;
  }
}
.bttn.blue {
  background: $blue;
  &:hover,
  &:focus {
    background: $gray_5;
  }
}
.bttn.upper {
  border-radius: 15px 15px 0 0;
}
.bttn {
  a {
    padding: 15px 25px 15px 25px;
  }
}
.sections-header .bttn,
.bttn.large {
  @include rem(font-size, 20px);
  padding: 15px 25px;
  line-height: 1.1;
}
@mixin bttn-large() {
  @include rem(font-size, 20px);
  padding: 15px 25px;
  line-height: 1.1;
}

// link field (knop) shown as button
.field-name-field-knop .bttn {
  display: inline-block;
  margin-bottom: 1em;
}

// Related content in sidebar
.bttn,
.bttn a {
  display: block;
  text-align: center;
  color: $white;
  text-decoration: none;
  @include rem(font-size ,15px);
  padding: 5px;
  &:hover,
  &:focus {
    color: $white;
  }
}
a[class*=bttn][class*=thin],
[class*=bttn][class*=thin] > a {
  border-radius: 10px 10px 0 0;
  padding: 5px;
}

.views-exposed-form {
  .btn.btn-default.form-submit {
    background: $blue;
    border: none;
    &:hover,
    &:focus {
      background: $gray_5;
    }
    &#edit-reset {
      background: $gray_3;
      &:hover,
      &:focus {
        background: $gray_5;
      }
    }
  }
}
