html, body, .front {
	min-height: 100%;
	width: 100%;
}

html {
	position: relative;
	overflow-y: scroll;
	overflow-x: hidden;
	box-sizing: border-box;
}

body {
	position: relative;
	margin: 0;
	min-height: 100%;
	&.front {
		padding-top: 0;
		overflow: hidden;
		.container-fluid {
			padding: 0;
		}
	}
}

// ---------------------------------------------------------------------------------
//  HEADER
// ---------------------------------------------------------------------------------
.head,
.main-navigation .row:first-child [class*=col-] {
	border-bottom: 1px solid $gray_2;
}
.has-news-on-homepage .main-navigation,
.front .main-navigation .row:first-child [class*=col-] {
	border-bottom: none;
}
.has-news-on-homepage .main-navigation .col-lg-36 {
	padding-bottom: 0;
}f
.main-navigation  .col-lg-36 {
	overflow:hidden;
}
.has-news-on-homepage .main-navigation .row:first-child [class*=col-] {
	border: none;
}
.navbar-header {
	> [class*=col-]:first-child {
		padding-left: 0;
	}
}
.head header {
	background-color: $gray_1;
}

.site-slogan {
	@include rem(font-size, 15px);
	color: $gray_4;
	@include rem(line-height, 20px);
	margin: 10px 0 0 0;
}
.site-slogan-wrapper.mobile {
	@include rem(padding-top,20px);
	@include rem(margin-bottom,20px);
	text-align: center;
}

// Secondary menu
.nav.navbar-nav.secondary {
	margin: 0 0 0 -10px;
	li {
		float: left;
		display: block;
		@include rem(font-size, 15px);
	}
	a {
		color: $gray_3;
		padding: 10px;
		&:hover,
		&:focus {
			color: $blue;
		}
	}
}

// Language menu
.language-switcher-locale-url {
	@extend %reset-list;
	@include rem(line-height,14px);
	li {
		float: left;
		display: block;
		border-left: 1px solid $gray_2;
		@include rem(font-size, 15px);
		&:first-child {
			border: none;
		}
	}
	a {
		display: block;
		text-transform: uppercase;
		color: $gray_3;
		padding: 20px 10px 10px 10px;
		text-decoration: none;
		@media (max-width: $screen-sm-max) {
			padding-top: 1.2em;
		}
		&:hover,
		&:focus {
			color: $blue;
		}
	}
}

// Primary menu
.main-navigation {
	// .row:first-child {
	// 	margin-bottom: 5px;
	// }
	.navbar-nav {
		margin-left: -15px;
		@media (max-width: $screen-sm) {
			padding: 10px;
		}
		li {
			@media (max-width: $screen-sm) {
				border-bottom: 1px solid $gray_7;
			}
		}
		li:first-child a {
			//padding-left: 0;
		}
		li.active:not(.first) a {
			color: $blue;
		}
		a {
			@extend %News_Gothic_bold;
			color: $gray_3;
			padding: 20px 15px;
			text-decoration:  none;
			@media (max-width: $screen-sm-max) {
				padding: 10px 8px;
				@include rem(font-size, 16px);
			}
			&:hover,
			&:focus,
			&:active {
				color: $blue;
			}
		}
	}
}

.primary.mobile {
	border-bottom: 1px solid $gray_7;
	.navbar-collapse {
		background: $white;
	}
	.navbar-nav,
	.navbar-nav.secondary {
		margin: 0 -15px 0 -15px;
		li {
			@media (max-width: $screen-sm) {
				border-top: 1px solid $gray_7;
			}
		}
		a {
			@extend %News_Gothic_bold;
			color: $gray_3;
			padding: 10px 15px;
			&:hover,
			&:focus {
				background: $blue;
				color: $white;
				span {
					color: inherit;
				}
			}
			&.active {
				color: $blue;
				background: $white;
			}
		}
		&.secondary {
			margin-top: 10px !important;
			li,
			a {
				display: block;
				float: none;
			}
		}
	}
	.language-switcher-locale-url {
		float: right;
		margin-right: -14px;
		a {
			padding: 1em 9px 0.7em;
		}
	}
}

.button-wrapper {
	margin-top: 15px;
	@extend .col-lg-offset-2;
	@media (max-width: $screen-sm-max) {
		@include rem(font-size, 16px);
		margin-top: 10px;
		margin-bottom: 10px;
	}
}

// Breadcrumb
.breadcrumb {
	padding-left: 0;
	padding-right: 0;
	@include rem(font-size,12px);
	& > .active,
	a,
	& > li + li::before {
		color: $gray_3;
	}
	@media (max-width: $screen-xs) {
		display: none;
	}
}
.navbar-toggle {
	background-color: rgba(0, 0, 0, 0);
	background-image: none;
	border: 1px solid rgba(0, 0, 0, 0);
	border-radius: 0;
	margin-bottom: 8px;
	//margin-right: 0;
	margin-top: 6px;
	padding: 0 0px 9px 46px;
	position: relative;
	@media (max-width:$screen-xs) {
		margin-right: 0 !important;
	}
}
.navbar-default .navbar-toggle .icon-bar {
	border-radius: 0;
	height: 7px;
	width: 40px;
	left: -50px;
	position: relative;
}
.navbar-label {
	line-height: 16px;
	position: absolute;
	left: 0;
	text-transform: uppercase;
	top: 0;
	@include rem(font-size, 15px);
}
.navbar-toggle .icon-bar {
	background: $gray_5;
	border-radius: 0;
	height: 3px;
}
.navbar-toggle.active {
	 .icon-bar {
    	background: $blue;
	}
	.navbar-label {
		color: $blue;
	}
}

// Content
@media (min-width: $screen-sm-min) {
	.wysiwyg_wrapper [class*=col]:first-child {
		padding-left: 0;
	}
	.wysiwyg_wrapper [class*=col]:last-child {
		padding-right: 0;
	}
}

.front .container .highlighted {
	@include rem(padding, 35px  0 55px 0);
	@media (min-width: $screen-xs-min) {
		@include rem(padding, 65px  0 85px 0);
	}
	@media (max-width: $screen-xs) {
		@include rem(padding, 25px  0 35px 0);
	}
}

.region-content {
	.field-name-body,
	.field-name-field-body {
		@include rem(padding, 30px 0);
		@media (max-width: $screen-sm) {
			@include rem(padding, 10px 0);
		}
		h2 {
			margin: 0;
			@include rem(font-size,24px);
			@include rem(margin-bottom,15px);
			//@extend %News_Gothic_bold;
		}
		ul, ol {
			padding-left: 0;
			margin-left: 15px;
			li {
				@include rem(margin-bottom,3px);
				margin-left: 0 !important;
			}
		}
		ol {
		  margin-left: 0;
		}
		ul,ol,p {
			margin-bottom: 20px;
		}
		table {
			max-width: 100%;
			width: 100%;
		}
	}
}
.region-sidebar-second .view-content {
	padding: 30px 0;
}
p:last-child {
	margin-bottom: 0;
}

// Levesnverzekeringen
.center {
	text-align: center;
}

// Sub pages summary
.content-top {
	// border-bottom: 1px solid $gray_2;
}
.view-summary  {
	.views-field-field-pagina-intro {
		background: none;
		color: $blue;
		padding-left: 0;
		padding-right: 0;
		h3 {
			color: $blue;
			@include rem(font-size,22px);
		}
	}
}

// News
.view-news-summary {
	.views-field-field-nieuws-samenvatting,
	.views-field-field-vacature-samenvatting {
		background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
		color: #33436e;
		padding: 15px 0;
	}
	h1 {
		span {
			display: block;
			margin-top: 0.5em;
			color: $blue;
			@include rem(font-size,14px);
		}
	}
}

// Reset padding for  breakpoint $phone.
@media (max-width: $screen-xs) {
	.container {
		padding-left: 10px !important;
		padding-right: 10px !important;
	}
	.row {
		margin-left: -10px !important;
		margin-right: -10px !important;
	}
	#navbar [class*=col] {
		//padding-left: 10px !important;
		//padding-right: 10px !important;
	}
	.wysiwyg_wrapper [class*=col] {
		padding-left: 10px !important;
		padding-right: 10px !important;
	}
	.wysiwyg_wrapper [class*=col]:last-child {
		padding-right: 0 !important;
	}
}

.logo.mobile {
	//padding-left: 0px !important;
	max-width: 223px;
}

.logo {
	width: 169.2px;
	height: 37.5px;
}


// FRONTPAGE
/* background slider
--------------------------------- */
.slider.container-fluid {
	padding-left: 0;
	padding-right: 0;
}

.view-frontpage-slideshow {
	height: 100%;
	.carousel,
	.carousel-inner {
		height: 100%;
		.carousel-caption-intro {
			position: absolute;
			left: 5%;
			top: 30px;
			z-index: 10;
			width: 65%;
			min-width: 350px;
			max-width: 600px;
			color: $blue_8;
			text-shadow: 1px 1px 2px rgba(0,0,0,.3);
			@include rem(font-size ,22px);
			@extend %News_Gothic_bold;
			line-height: 1.3;
			text-align: left !important;
			visibility: none;
			animation-name: slideInLeft;
			animation-duration: 1s;
			animation-fill-mode: both;
			animation-delay: .5s;
			a {
				color: $white;
				&:hover, &:focus {
					color: $blue;
				}
			}
			h2 {
				margin-top: 0;
			}
			p {
				color: $white;
			}
		}
		.carousel-caption {
			position: absolute;
			left: 0;
			//top: 30%;
			right: 5%;
			bottom: 0;
			z-index: 10;
		}
		.full-screen{
			//height: calc(100vh - 125px); // javascript calculates height
			overflow: hidden;
			.views-field {
				height: 100%;
				overflow: hidden;
			}
			.field-content {
				display: block;
				height: 100%;
				width: 100%;
				background-size: cover;
				background-position: center;
				background-repeat: no-repeat;
			}
		}
	}

	.carousel-indicators {
		position: absolute;
		bottom: 0;
		left: 50%;
		z-index: 15;
		width: 60%;
		margin-left: -30%;
		padding-left: 0;
		list-style: none;
		text-align: center;
		@media screen and (min-width: 768px) {
			bottom: 0;
		}
	}

	.carousel-caption-content {
		position: relative;
		float: right;
		text-align: left;
		z-index: 10;
		padding: 0;
		width: 30%;
		min-width: 350px;
		max-width: 475px;
		background: $gray_6;
		color: $gray_4;
		border-radius: 15px 15px 0 0;
		text-shadow: none;
		visibility: none;
		animation-name: slideInRight;
		animation-duration: 1s;
		animation-fill-mode: both;
		animation-delay: 1s;
		h1 {
			width: 100%;
			@include rem(font-size,22px);
			text-align: center;
			background-color: $blue;
			color: $white;
			padding: 15px 25px 15px 25px;
			border-radius: 15px 15px 0 0;
			margin: 0;
		  &:hover,
		  &:focus {
		    background: $gray_5;
		  }
		  a {
		  	color: $white;
		  }
		}
		p, li {
			text-align: left;
			font-size: 1em;
		}
		ul {
			margin: 1em .5em;
		}
		h2 {
			@include rem(font-size,22px);
		}
		p, h2, h3 {
			margin: 20px;
		}
	}
}

/* BACKGROUND
-------------------------------------------------- */
.main-container{
	.container-content {
		background-color: #FFFFFF;
		background-repeat: no-repeat;
		//background-attachment: fixed;
		background-position: center center;
		background-size: cover;
	}
}
.page-node-8,
.page-node-9 {
	.main-container {
		.container-content {
			background-image: url(../images/bg_patronale_4-donker.jpg);
		}
	}
}
.page-node-6,
.page-node-7 {
	.main-container {
		.container-content {
			background-image: url(../images/bg_patronale_krediet2-donker.jpg);
		}
	}
}

.page-node-6,
.page-node-7,
.page-node-8,
.page-node-9 {
	//padding-bottom: 450px;
	.breadcrumb--container {
		display: none;
	}
	.main-navigation .row:first-child [class*=col-] {
		border-bottom: none;
	}
	.main-container {
		margin-top: -6px;
		.container-content {
			padding: 50px 0;
			text-align: left;
			min-height: 600px;
			min-height: 100vh;
			.field-name-body {
				padding-top: 0;
				color: $white;
				text-shadow: 1px 1px 2px rgba(0,0,0,.8);
				@include rem(font-size ,22px);
				@extend %News_Gothic_bold;
				line-height: 1.3;
				text-align: left !important;
				a {
					color: $blue_2;
					&:hover, &:focus {
						color: $blue;
					}
					&.bttn {
						display: inline-block;
						color: $white;
						&:hover, &:focus {
							color: $white;
						}
					}
				}
			}
			.content-top {
				border: none;
			}
			h1.page-header {
				// text-align: center;
				text-shadow: 1px 1px 2px rgba(0,0,0,.8);
				color: $blue_8;
				@include rem(font-size ,34px);
				@extend %News_Gothic_bold;
			}
			.content-body {
				.row:first-of-type [class*=offset] {
					margin-left: 0;
				}
				@media (min-width: 992px) {
					.row:first-of-type [class*=offset] {
						width: 63.88889%;
					}
				}
			}
		}
	}
}

// makelaars
.node-makelaar {
	margin-bottom: 20px;
	.location-locations-display,
	.field-name-field-producten {
		width: 50%;
		float: left;
	}
	h3.location-locations-header {
		display: none;
	}
	.location-locations-wrapper {
		.email,
		.tel {
			abbr.type {
				display: none;
			}
		}
		.adr {
			text-align: left;
			span.locality,
			span.postal-code {
			}
		}
	}
}

.view-makelaars .view-content,.view-empty {
  margin-bottom: 30px;
}
.col-lg-offset-2, .button-wrapper {
    margin-left: 0;
}

// fondsen
.node-type-fondsen {
	.field-name-field-bestand {
		padding-top: 1em;
		.table {
			th:last-of-type,
			td:last-of-type,
			img.file-icon {
				display: none;
			}
		}
	}
}
