
.content-body.sticky.at-bottom {
	position: relative;
}
.wrap {
	margin:0 auto;
	display:inline;
	top:0;
	z-index:0;
	vertical-align:top;
	max-width: 1170px;
}
.sticky .wrap  {
	position:fixed;
	display:block;
}
.at-bottom .wrap  {
	position:absolute;
	bottom:0;
	top:inherit;
}
.sticky aside {
	//margin-left:75%;
}
.sticky-stop {clear:both;}

/* responsive */
@media (max-width: 768px) {
.sticky .wrap, .at-bottom .wrap, .sidebar {
  	width:100%;
  	display:block;
  	position:static;
  }
}
