// Variables
$white: 			#fff;
$gray_1:  			rgb(236,236,236);
$gray_2:  			rgb(197,197,197);
$gray_3:  			rgb(155,155,155);
$gray_4:  			rgb(88,88,88);
$gray_5:  			rgb(111,111,111);
$gray_6:  			rgb(217,217,217);
$gray_7:  			rgb(177,177,177);
$blue:    			rgb(51,67,110);
$blue_2:  			rgb(134,193,207);
$blue_8:   			rgb(51,67,110);
$base-font-size: 	16px;

// Extends
%transition {
	transition: all .25s ease-in-out;
}
